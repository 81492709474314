import React from 'react'

const SplitContainer = ({
  children
}) => (
  <article className="split-container">
    {children}
  </article>
)
export default SplitContainer
