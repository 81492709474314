import React from 'react'
import Carousel from 'nuka-carousel'

import PrevArrow from './PrevArrow'
import NextArrow from './NextArrow'

class Testimonials extends React.Component {
  render() {
    return (
      <Carousel
        autoplay
        wrapAround
        renderCenterRightControls={({ previousSlide }) => <PrevArrow onClick={previousSlide} />}
        renderCenterLeftControls={({ nextSlide }) => <NextArrow onClick={nextSlide} />}>
        {this.props.items.map((item, i) => (
          <div key={i} className="carousel-item">
            <img src={item.image} alt={item.author} />
          </div>
        ))}
      </Carousel>
    )
  }
}

export default Testimonials
