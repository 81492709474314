import React from 'react'
import MailForm from '../MailForm'

const Newsletter = ({
  title,
  subTitle,
  description
}) => (
  <article>
    <h4>{subTitle}</h4>
    <h2>{title}</h2>
    <p>{description}</p>
    <MailForm />
  </article>
)

export default Newsletter
