import React from 'react'
import DownloadLinks from '../DownloadLinks'

const Hero = ({
  backgroundImage,
  backgroundColor,
  image,
  title,
  subTitle,
  description,
  downloadLinks
}) => (
  <article className="hero" style={{backgroundImage: `url(${backgroundImage})`, backgroundColor: backgroundColor}}>
    <aside>
      <figure><img src={image} alt="Hero" /></figure>
    </aside>
    <main>
      <h1>{title}</h1>
      <h3>{subTitle}</h3>
      <p>{description}</p>
      <DownloadLinks items={downloadLinks} />
    </main>
  </article>
)

export default Hero
