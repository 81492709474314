import React from 'react'
import SocialMedia from '../SocialMedia'
import DownloadLinks from '../DownloadLinks'
import Video from '../Video'

const About = ({
  backgroundImage,
  backgroundColor,
  image,
  video,
  title,
  subTitle,
  description,
  socialTitle,
  socialMedia,
  downloadLinks
}) => (
  <article id="about" className="about" style={{ backgroundImage: `url(${backgroundImage})`, backgroundColor: backgroundColor}}>
    <aside>
      <Video url={video} />
    </aside>
    <main>
      <header>
        <img src={image} />
        <aside>
          <h4>{subTitle}</h4>
          <h2>{title}</h2>
        </aside>
      </header>
      <p>{description}</p>
      <h5>{socialTitle}</h5>
      <SocialMedia icons={socialMedia} />
      <DownloadLinks items={downloadLinks} />
    </main>
  </article>
)

export default About
