import React from 'react'

const Video = ({ url }) => (
  <div className="video-wrapper">
    <iframe
      src={`//www.youtube.com/embed/${url}`}
      title="youtube"
      frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  </div>
)

export default Video
