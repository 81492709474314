import React from 'react'

const DownloadLinks = ({ items }) => (
  items ? (
    <ul className="download-links">
      {items.map((item, i) => (
        <li key={i}>
          <a href={item.url}><img src={item.icon} alt={item.altText} /></a>
        </li>
      ))}
    </ul>
  ) : null
)

export default DownloadLinks
