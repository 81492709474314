import React from 'react'
import Testimonials from '../Testimonials'

const FeatureTestimonials = ({
  backgroundImage,
  backgroundColor,
  image,
  title,
  subTitle,
  description,
  testimonials
}) => (
  <article className="feature feature-testimonials" style={{backgroundImage: `url(${backgroundImage})`, backgroundColor: backgroundColor}}>
    <div className="wrapper">
      <main>
        <div className="center">
          <h4>{subTitle}</h4>
          <h2>{title}</h2>
          <p>{description}</p>
        </div>
      </main>
      <aside>
        <Testimonials items={testimonials} />
      </aside>
    </div>
  </article>
)

export default FeatureTestimonials
