import React from 'react'
import DownloadLinks from '../DownloadLinks'

const Feature = ({
  backgroundImage,
  backgroundColor,
  image,
  title,
  alignCenter,
  subTitle,
  description,
  downloadLinks
}) => (
  <article className={`feature ${alignCenter ? 'supercenter' : ''}`} style={{backgroundImage: `url(${backgroundImage})`, backgroundColor: backgroundColor}}>
    <div className="wrapper">
      <main>
        <div className="center">
          {subTitle ? <h4>{subTitle}</h4> : null}
          <h2>{title}</h2>
          <p>{description}</p>
          <DownloadLinks items={downloadLinks} />
        </div>
      </main>
      {image ? (
        <aside>
          <figure><img src={image} alt="Feature" /></figure>
        </aside>
      ) : null}
    </div>
  </article>
)

export default Feature
