import React from 'react'

function PrevArrow(props) {
  const { onClick } = props

  return (
    <div className="button prev-button" onClick={onClick}>
      <span><i className={'fa fa-chevron-right'}></i></span>
    </div>
  );
}

export default PrevArrow
