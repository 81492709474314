import React, {Component} from 'react';

class MailForm extends Component {
  constructor(props) {
    super(props);
    this.state = { isSuccess: false };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = e => {
    const self = this;
    e.preventDefault();
    fetch('/', {
      method: 'post',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encodeURI(`form-name=contact&email=${document.getElementById('email').value}`)
    }).then(function(response) {
      self.setState({
        isSuccess: true
      })
    })
  };

  render() {
    if(this.state.isSuccess) {
      return <h2>Thank you for subscribing!</h2>
    }

    return (
      <form style={{width: '100%'}} name="contact" data-netlify onSubmit={this.handleSubmit}>
        <input type="hidden" name="form-name" value="contact" />
        <input type="email" id="email" name="email" placeholder="Email" />
      </form>
    )
  }
}

export default MailForm
