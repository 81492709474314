import React from 'react'
import marked from 'marked'
import SocialMedia from '../SocialMedia'

const rawMarkup = description => ({ __html: marked(description, {sanitize: true})})

const Contact = ({
  title,
  subTitle,
  description,
  socialMedia
}) => (
  <article>
    <h4>{subTitle}</h4>
    <h2>{title}</h2>
    <div dangerouslySetInnerHTML={rawMarkup(description)} />
    <SocialMedia icons={socialMedia} />
  </article>
)

export default Contact
