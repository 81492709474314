import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import Hero from '../components/Hero'
import About from '../components/About'
import Feature from '../components/Feature'
import FeatureTestimonials from '../components/FeatureTestimonials'
import Contact from '../components/Contact'
import Newsletter from '../components/Newsletter'
import SplitContainer from '../components/SplitContainer'

export const HomePageTemplate = ({
  title,
  description,
  legal,
  socialMedia,
  downloadLinks,
  hero,
  about,
  feature1,
  feature2,
  feature3,
  feature4,
  feature5,
  newsletter,
  contact
}) => (
  <Layout
    title={title}
    description={description}
    legal={legal}
    socialMedia={socialMedia}>
    <section>
      <Hero
        backgroundImage={hero.backgroundImage}
        backgroundColor={hero.backgroundColor}
        image={hero.image}
        title={hero.title}
        subTitle={hero.subTitle}
        description={hero.description}
        downloadLinks={downloadLinks}
      />
      <About
        backgroundImage={about.backgroundImage}
        backgroundColor={about.backgroundColor}
        image={about.image}
        video={about.video}
        title={about.title}
        subTitle={about.subTitle}
        description={about.description}
        socialTitle={about.socialTitle}
        socialMedia={socialMedia}
        downloadLinks={downloadLinks}
      />
      <FeatureTestimonials
        backgroundImage={feature1.backgroundImage}
        backgroundColor={feature1.backgroundColor}
        title={feature1.title}
        subTitle={feature1.subTitle}
        description={feature1.description}
        testimonials={feature1.testimonials}
      />
      <Feature
        backgroundImage={feature2.backgroundImage}
        backgroundColor={feature2.backgroundColor}
        title={feature2.title}
        subTitle={feature2.subTitle}
        image={feature2.image}
        description={feature2.description}
      />
      <Feature
        backgroundImage={feature3.backgroundImage}
        backgroundColor={feature3.backgroundColor}
        title={feature3.title}
        subTitle={feature3.subTitle}
        image={feature3.image}
        description={feature3.description}
      />
      <Feature
        backgroundImage={feature4.backgroundImage}
        backgroundColor={feature4.backgroundColor}
        title={feature4.title}
        subTitle={feature4.subTitle}
        image={feature4.image}
        description={feature4.description}
      />
      <Feature
        backgroundImage={feature5.backgroundImage}
        backgroundColor={feature5.backgroundColor}
        alignCenter={feature5.alignCenter}
        title={feature5.title}
        subTitle={feature5.subTitle}
        description={feature5.description}
        downloadLinks={downloadLinks}
      />
      <SplitContainer>
        <Newsletter
          title={newsletter.title}
          subTitle={newsletter.subTitle}
          description={newsletter.description}
        />
        <Contact
          title={contact.title}
          subTitle={contact.subTitle}
          description={contact.description}
          socialMedia={socialMedia}
        />
      </SplitContainer>
    </section>
  </Layout>
)

const HomePage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <HomePageTemplate
      title={frontmatter.title}
      legal={frontmatter.legal}
      socialMedia={frontmatter.socialMedia}
      downloadLinks={frontmatter.downloadLinks}
      hero={frontmatter.hero}
      about={frontmatter.about}
      feature1={frontmatter.feature1}
      feature2={frontmatter.feature2}
      feature3={frontmatter.feature3}
      feature4={frontmatter.feature4}
      feature5={frontmatter.feature5}
      newsletter={frontmatter.newsletter}
      contact={frontmatter.contact}
    />
  )
}

export default HomePage

export const homePageQuery = graphql`
  query HomePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        description
        legal {
          label
          value
        }
        socialMedia {
          icon
          url
        }
        downloadLinks {
          icon
          url
          altText
        }
        hero {
          backgroundImage
          backgroundColor
          image
          title
          subTitle
          description
        }
        about {
          backgroundImage
          backgroundColor
          video
          image
          title
          subTitle
          socialTitle
          description
        }
        feature1 {
          backgroundImage
          backgroundColor
          title
          subTitle
          description
          testimonials {
            author
            image
          }
        }
        feature2 {
          backgroundImage
          backgroundColor
          image
          title
          subTitle
          description
        }
        feature3 {
          backgroundImage
          backgroundColor
          image
          title
          subTitle
          description
        }
        feature4 {
          backgroundImage
          backgroundColor
          image
          title
          subTitle
          description
        }
        feature5 {
          backgroundImage
          backgroundColor
          alignCenter
          title
          subTitle
          description
        }
        newsletter {
          title
          subTitle
          description
        }
        contact {
          title
          subTitle
          description
        }
      }
    }
  }
`
