import React from 'react'

function NextArrow(props) {
  const { onClick } = props

  return (
    <div className="button next-button" onClick={onClick}>
      <span><i className={'fa fa-chevron-left'}></i></span>
    </div>
  );
}

export default NextArrow
